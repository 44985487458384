<template>
  <div class="manual">
    <div class="man-title"><a href="https://www.pmop.cn/">PubMed Optimizer</a> 检索文献使用技巧</div>
    <div class="man-p">
      PubMed是美国国立医学图书馆 (National Library of Medicine, NLM)所属的国家生物技术信息中心 (National Center for Biotechnology Information，NCBI)建立的生物医学文献检索系统，使用NCBI 的Entrez检索平台，是检索全世界生物医学期刊论文的重要工具。PubMed目前已是医学科研工作者必备的文献检索平台。
    </div>
    <div class="man-img"><img src="/static/manual/1.png" alt=""></div>
    <div class="man-c">PubMed官网（pubmed.gov）</div>
    <div class="man-null"></div>
    <div class="man-p">为了让这一平台更加符合中国医学科研工作者的使用习惯，银杏叶科研平台IT技术团队历时半年，呕心沥血，攻坚克难，开发了PubMed Optimizer（PubMed文献检索优化系统），今日网页版和小程序版均开放测试，下面简单介绍一下PubMed Optimizer平台的主要功能特色：</div>
    <div class="man-w">功能特色一：与PubMed数据库完全一致，同步更新，检索结果与PubMed完全相同</div>
    <div class="man-img"><img src="/static/manual/2.png" alt=""></div>
    <div class="man-c">银杏叶PubMed Optimizer平台</div>
    <div class="man-null"></div>
    <div class="man-w">功能特色二：实现本地化数据检索，比PubMed官网更快，改善官网检索数据延迟</div>
    <div class="man-img"><img src="/static/manual/3.png" alt=""></div>
    <div class="man-null"></div>
    <div class="man-w">功能特色三：每篇文献智能计算综合评分（银杏因子），综合影响因子、中科院分区、Altmetrics媒体影响力评分和他引次数，优质文献一目了然</div>
    <div class="man-img"><img src="/static/manual/4.png" alt=""></div>
    <div class="man-null"></div>
    <div class="man-w">功能特色四：整合SCI-Hub和PMC全文数据库，实现一键下载全文</div>
    <div class="man-img"><img src="/static/manual/5.png" alt=""></div>
    <div class="man-null"></div>
    <div class="man-img"><img src="/static/manual/6.png" alt=""></div>
    <div class="man-null"></div>
    <div class="man-w">功能特色五：检索结果中文献所在期刊历年影响因子动态查看</div>
    <div class="man-img"><img src="/static/manual/7.png" alt=""></div>
    <div class="man-null"></div>
    <div class="man-w">功能特色六：根据SCI期刊影响因子快速筛选优质文献</div>
    <div class="man-img"><img src="/static/manual/8.png" alt=""></div>
    <div class="man-null"></div>
    <div class="man-w">功能特色七：文献标题和摘要自动中英文翻译，快速阅读无障碍</div>
    <div class="man-img"><img src="/static/manual/9.png" alt=""></div>
    <div class="man-null"></div>
    <div class="man-w">功能特色八：微信扫描即刻登录，文献批量收藏，在线文献管理，无需担心文献丢失</div>
    <div class="man-img"><img src="/static/manual/10.png" alt=""></div>
    <div class="man-null"></div>
    <div class="man-img"><img src="/static/manual/11.png" alt=""></div>
    <div class="man-null"></div>
    <div class="man-img"><img src="/static/manual/12.png" alt=""></div>
    <div class="man-null"></div>
    <div class="man-w">功能特色九：单篇文献或收藏文献，一键导入EndNote，一键输出引文格式</div>
    <div class="man-img"><img src="/static/manual/13.png" alt=""></div>
    <div class="man-null"></div>
    <div class="man-img"><img src="/static/manual/14.png" alt=""></div>
    <div class="man-null"></div>
    <div class="man-img"><img src="/static/manual/15.png" alt=""></div>
    <div class="man-null"></div>
    <div class="man-w">功能特色十：在线文献管理中收藏文献自动更新影响因子和引用次数</div>
    <div class="man-img"><img src="/static/manual/16.png" alt=""></div>
    <div class="man-null"></div>
    <div class="man-w">功能特色十一：通过微信小程序版打通电脑端与手机移动端，零碎时间刷视频新闻，不如刷刷最新文献，筛选优质文献收藏后，同步在电脑端下载全文精读</div>
    <div class="man-img-m"><img src="/static/manual/17.png" alt=""></div>
    <div class="man-null"></div>
    <div class="man-img-m"><img src="/static/manual/18.png" alt=""></div>
    <div class="man-null"></div>
    <div class="man-img-m"><img src="/static/manual/19.png" alt=""></div>
    <div class="man-null"></div>
    <div class="man-w">功能特色十二：整合SCI期刊影响因子查询和国自然基金查询，一站式解决杂志影响因子和基金查询问题</div>
    <div class="man-img"><img src="/static/manual/20.png" alt=""></div>
    <div class="man-null"></div>
    <div class="man-img"><img src="/static/manual/21.png" alt=""></div>
    <div class="man-null"></div>
    <div class="man-p">其他特色功能：PubMed Optimizer期待聪明睿智的您来提出，银杏叶平台IT团队讲倾力为您开发，因为这就是为您量身打造的平台。</div>
    <div class="man-null"></div>
    <div class="man-p">PubMed Optimizer平台开放测试，日常招募平台测试专员和学术顾问，欢迎您的加入，方式如下：</div>
    <div class="man-p">搜索加平台管理员微信号：18516097297，截图发给平台管理员。</div>
    <div class="man-p">招募平台测试专员5000名，加入后可索取永久使用权限，名额有限，先到先得。</div>
    <div class="man-img-wechat"><img src="/static/manual/22.png" alt=""></div>
    <div class="man-c">PubMed Optimizer管理员微信号</div>
    <div class="man-ft">
      <div class="link-box" @click="unsubscribe">
        <div class="title">点击这里取消订阅</div>
        <div class="title">click to unsubscribe</div>
      </div>
    </div>
  </div>
</template>
<script>
import { authenticate, catchExpired } from '../utils/auth'
export default {
  data () {
    return {
      email: ''
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.query && to.query.email) {
      document.title = 'PubMed Optimizer 用户使用手册'
      next(vm => {
        vm.email = to.query.email
        vm.sendCountMsg(to.query.email, to.query.type)
      })
    } else {
      document.title = 'PubMed Optimizer 用户使用手册'
      next()
    }
  },
  methods: {
    loadPFList () {
      this.$http.get(`${this.httpRoot}/paper/fulltext`, authenticate())
      .then(res => res.json())
      .then(res => {
        //
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    unsubscribe () {
      this.$http.post(`${this.httpRoot}/auth/email/click`, {email: this.email, type: 'unsubscribe'}, authenticate())
      .then(res => res.json())
      .then(res => {
        //
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    sendCountMsg (email, type) {
      this.$http.post(`${this.httpRoot}/auth/email/click`, {email, type}, authenticate())
      .then(res => res.json())
      .then(res => {
        //
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
</script>
<style lang="less">
.manual {
  padding-top: 20px;
  line-height: 22px;
  max-width: 1200px;
  margin: 0 auto;
}
.man-title {
  text-align: center;
  font-size: 22px;
  margin: 10px 0;
}
.man-p {
  text-indent: 32px;
}
.man-img {
  padding-left: 32px;
  padding-right: 32px;
  margin: 10px 0;
  img {
    width: 100%;
  }
}
.man-img-m {
  padding-left: 300px;
  padding-right: 300px;
  margin: 10px 0;
  img {
    width: 100%;
  }
}
.man-img-wechat {
  padding-left: 400px;
  padding-right: 400px;
  margin: 10px 0;
  img {
    width: 100%;
  }
}
.man-c {
  text-align: center;
}
.man-w {
  font-weight: 600;
  text-indent: 32px;
  margin: 10px 0;
}
.man-null {
  height: 22px;
  width: 100%;
}
.man-ft {
  margin: 40px 0;
  .link-box {
    width: 200px;
    margin: 0 auto;
    padding: 10px 0;
    text-align: center;
    background-color: #e1e1e1;
    cursor: pointer;
    border-radius: 4px;
  }
}
</style>
